<template>
  <b-container class="test-page">
    <b-row>
      <b-col></b-col>
      <b-col class="test-title">培立优2023中考英语冲刺</b-col>
      <b-col>
        <countdown @afterCountdown="afterCountdown"></countdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="test-subtitle">扫雷尖兵词汇效果测评</b-col>
    </b-row>
    <div>
      <select-question
        v-for="question in examQuestion.simulationExamQuestions"
        :examQuestion="question"
        
        @changeRadio="changeRadio"
        :key="question.questionId"
        
      />
    </div>
    <b-row>
        <b-col>
            <b-button variant="success" class="submitButton" @click="submitTest">提交试卷</b-button>
            <b-button class="cancelButton" @click="cancelTest">放弃考试</b-button>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import countdown from "@/components/countdown/countdown.vue";
import SelectQuestion from "@/views/english-mock/components/SelectQuestion.vue";
export default {
  data() {
    return {
      examQuestion: {
        sectionScore: 0,
        plyTkSectionId: 2,
        description: "",
        stemStatement: "",
        simulationExamQuestions: [
          {
            standardAnswerKey: "",
            questionId: "7c90cfa1-1df2-4805-b363-f6222fd1df7d",
            statement:
              '<p><span lang="EN-US">Mr. Jackson is a popular writer, and we all like reading&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;books.</span></p>',
            variation: 21,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "his",
              },
              {
                value: "B",
                statement: "her",
              },
              {
                value: "C",
                statement: "their",
              },
              {
                value: "D",
                statement: "your",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "dd03ef21-f8cf-4fb7-a733-50885c07e6d0",
            statement:
              '<p><span lang="EN-US">Usually I make breakfast for my family&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;Saturdays.</span></p>',
            variation: 22,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "at",
              },
              {
                value: "B",
                statement: "in",
              },
              {
                value: "C",
                statement: "on",
              },
              {
                value: "D",
                statement: "to",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "a9f8b2eb-987d-4cf0-9caf-3489ad9f9b79",
            statement:
              '<p><span lang="EN-US">Wash your hands before meals,&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;you may get ill.</span></p>',
            variation: 23,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "and",
              },
              {
                value: "B",
                statement: "but",
              },
              {
                value: "C",
                statement: "or",
              },
              {
                value: "D",
                statement: "so",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "c552b9c2-71ef-4bc8-935e-7beead9a8040",
            statement:
              '<p><span lang="EN-US">&mdash;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;you give me a hand? I can&rsquo;t move the box by myself.</span></p>\n<p><span lang="EN-US">&mdash;No problem.</span></p>',
            variation: 24,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "Could",
              },
              {
                value: "B",
                statement: "Should",
              },
              {
                value: "C",
                statement: "Must",
              },
              {
                value: "D",
                statement: "Need",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "f3ae9e49-eba5-4d0b-8887-7347da3a9ee9",
            statement:
              '<p><span lang="EN-US">&mdash;What&rsquo;s your present for our daughter&rsquo;s birthday</span>？</p>\n<p><span lang="EN-US">&mdash;It&rsquo;s a&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>. I want to give her a surprise.</span></p>',
            variation: 25,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "plan",
              },
              {
                value: "B",
                statement: "dress",
              },
              {
                value: "C",
                statement: "card",
              },
              {
                value: "D",
                statement: "secret",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "d2589b33-ef41-46b0-8b5b-977c4dcaa32d",
            statement:
              '<p><span lang="EN-US">&mdash;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;do you visit your grandparents, Tom</span>？</p>\n<p><span lang="EN-US">&mdash;Twice a month.</span></p>',
            variation: 26,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "How much",
              },
              {
                value: "B",
                statement: "How often",
              },
              {
                value: "C",
                statement: "How long",
              },
              {
                value: "D",
                statement: "How soon",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "a15e65df-d167-4484-ae43-a63a0afd1dae",
            statement:
              '<p><span lang="EN-US">Zhaozhou Bridge is one of&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;stone bridges in the world.</span></p>',
            variation: 27,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "old",
              },
              {
                value: "B",
                statement: "older",
              },
              {
                value: "C",
                statement: "oldest",
              },
              {
                value: "D",
                statement: "the oldest",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "940b28b3-cf0c-480f-869f-e05364cb0acc",
            statement:
              '<p><span lang="EN-US">Don\'t&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;your new haircut. You look pretty with it.</span></p>',
            variation: 28,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "pay for",
              },
              {
                value: "B",
                statement: "play with",
              },
              {
                value: "C",
                statement: "depend on",
              },
              {
                value: "D",
                statement: "worry about",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "40afbb6f-a55c-44ae-9d5d-e1b27a5c5ee5",
            statement:
              '<p><span lang="EN-US">If you take this train, you&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;in Shanghai in five hours.</span></p>',
            variation: 29,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "arrive",
              },
              {
                value: "B",
                statement: "will arrive",
              },
              {
                value: "C",
                statement: "arrived",
              },
              {
                value: "D",
                statement: "have arrived",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "a322df94-f383-4091-a8f6-5dd5109b2f4d",
            statement:
              '<p><span lang="EN-US">I&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;on the computer when Frank called me last night.</span></p>',
            variation: 30,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "work",
              },
              {
                value: "B",
                statement: "will work",
              },
              {
                value: "C",
                statement: "was working",
              },
              {
                value: "D",
                statement: "am working",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "4afd571d-cb83-4c5b-bcac-c159b4c4e38a",
            statement:
              '<p><span lang="EN-US">We&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;each other since I came to Beijing, but we send emails very often.</span></p>',
            variation: 31,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "don't see",
              },
              {
                value: "B",
                statement: "didn't see",
              },
              {
                value: "C",
                statement: "won't see",
              },
              {
                value: "D",
                statement: "haven't seen",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "ef02d2bd-9d40-4d7b-b5d2-a5de8850d184",
            statement:
              '<p><span lang="EN-US">&mdash;What\'s that noise, Sam</span>？</p>\n<p><span lang="EN-US">&mdash;My little brother&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u></span><span lang="EN-US">&nbsp;</span><span lang="EN-US">with his toy car now.</span></p>',
            variation: 32,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "will play",
              },
              {
                value: "B",
                statement: "is playing",
              },
              {
                value: "C",
                statement: "plays",
              },
              {
                value: "D",
                statement: "played",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "54da6a0f-a844-4d8e-85da-87a6a3131f57",
            statement:
              '<p><span lang="EN-US">&mdash;These dresses are really nice and I can\'t decide which one to buy.</span></p>\n<p><span lang="EN-US">&mdash;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>. There&rsquo;s no hurry.</span></p>',
            variation: 33,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "I think so.",
              },
              {
                value: "B",
                statement: "It's a piece of cake.",
              },
              {
                value: "C",
                statement: "Take your time.",
              },
              {
                value: "D",
                statement: "I'm afraid to.",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "f09d0186-8d39-4021-ae4f-9ddfa9951f17",
            statement:
              '<p><span lang="EN-US">The kite&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>&nbsp;in China more than 2</span>,<span lang="EN-US">000 years ago.</span></p>',
            variation: 34,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "invents",
              },
              {
                value: "B",
                statement: "invented",
              },
              {
                value: "C",
                statement: "is invented",
              },
              {
                value: "D",
                statement: "was invented",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
          {
            standardAnswerKey: "",
            questionId: "a90cd9d7-2da9-444c-bf88-6238886b8058",
            statement:
              '<p><span lang="EN-US">&mdash;Do you know&nbsp;<u>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </u>?</span></p>\n<p><span lang="EN-US">&mdash;At 9</span>:<span lang="EN-US">00 tomorrow morning.</span></p>',
            variation: 35,
            voiceUrl: null,
            options: [
              {
                value: "A",
                statement: "when the video meeting began",
              },
              {
                value: "B",
                statement: "when did the video meeting begin",
              },
              {
                value: "C",
                statement: "when the video meeting will begin",
              },
              {
                value: "D",
                statement: "when will the video meeting begin",
              },
            ],
            blankCount: null,
            stemId: null,
            questionScore: 1.5,
          },
        ],
      },
    };
  },
  components: {
    SelectQuestion,
    countdown,
  },
  methods: {
    afterCountdown() {},
    changeRadio(){},
    submitTest(){
        this.$router.replace({
            name:'SmallModuleResultsTestEnd'
        })
    },
    cancelTest(){
        this.$router.replace({
            name:'Home'
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.test-page {
  background: white;
  min-height: 500px;
  padding: 20px ;
}
.test-title{
  margin: 5px;
  font-weight: bold;
}
.test-subtitle{
  margin: 5px;
  font-size: 30px;
  font-weight: bold;
}
.cancelButton{
  margin: 10px;
}
.submitButton{
  margin: 10px;
  font-weight: bold;
}
</style>